import * as React from "react"

export default function BlockACC({
  subheading,
  heading,
  text,
  buttons,
  ...props
}) {
  return (
    <section py="6|8|12|20" {...props}>
      <div variant="container">
        <div
          display="flex"
          flexDirection="column|row"
          justifyContent="space-between"
          alignItems="flex-start|center"
        >
          <div flex="1">
            {subheading && (
              <p color="primary" textTransform="uppercase" m="0">
                {subheading}
              </p>
            )}
            {heading && (
              <h1 variant="heading.h1" fontWeight="bolder" lineHeight="tight">
                {heading}
              </h1>
            )}
            {text && (
              <p variant="text.lead" mt="2">
                {text}
              </p>
            )}
            
<div class="HighSchoolawards">
<ul fontSize="xl" py="0">
<h1>LOUISIANA TECH FRESHMEN 2021-22 ATHLETIC ACCOMPLISHMENTS</h1>
  <li>Played nine games while making four starts … Started all three games in the final regular season series at Western Kentucky (May 5-6) … Made her first collegiate start in a loss to Middle Tennessee (3/25) … Recorded her first career hit and RBI with a single during a 7-6 loss at Western Kentucky (5/5) … Saw limited time at the beginning of the season due to injury … Posted a 1.000 Fielding Percentage while making three putouts.</li>
<h1>HIGH SCHOOL ATHLETIC ACCOMPLISHMENTS</h1>
  <li>2022 Two Time - 5A Arizona State High School Softball Champions (Willow Canyon)</li>
  <li>2022 All-Region 1st Team (5A)</li>
  <li>2022 All-Conference 1st Team (5A)</li>
  <li>2022 All time Varsity HITS leader - Willow Canyon (172hits)</li>
  <li>2022 Best 4 year varsity Batting Average - Willow Canyon (.562)</li>
  <li>2021-2022 - Varsity Wrestling Team</li>
  <li>2022 Wrestling - Finnished 6th State Championship</li>
  <li>2022 Wrestling - Region Champion</li>
  <li>2022 Wrestling Team MVP</li>
  <li>2021 5A Arizona State High School Softball Champions (Willow Canyon)</li>
  <li>2021 Extra Innings First Team High School All American 1st Team - Infieldl</li>
  <li>2021 MaxPreps All American High School 2nd Team - Infield</li>
  <li>2021 Arizona Republic's All Arizona High School 1st Team - Infield</li>
  <li>2021 Arizona Sports Awards USA TODAY - Player of the Year Nominee</li>
  <li>2021 All West Valley Preps High School 1st Team - Infield</li>
  <li>2021 All West Valley Preps High School 2nd Runner Up Player of the Year</li>
  <li>2021 All-Conference 1st Team (5A)</li>
  <li>2021 All-Region 1st Team (5A)</li>
  <li>2021 All-District 1st Team (5A)</li>
  <li>2021 Offensive Player of the Year (Willow Canyon)</li>
  <li>2021 Spring All-Academic 1st Team</li>
  <li>2020-2021 - Varsity Wrestling Team</li>
  <li>2020 Willow Canyon Team MVP - Softball *Shorten Covid-19 Season 9 Games</li> 
  <li>2019-2020 - Varsity Wrestling Team</li>
  <li>2019 All-Region 1st Team - Softball</li>
  <li>2019 All-District 1st Team - Softball</li>
  <li>2019 Willow Canyon Team MVP - Softball</li>
  <li>2019 Voted All-Tournament Team at the 16th Annual Wrangler Classic High School Softball Tournament</li>
  <li>2019 Varsity Softball as a Freshman</li>
</ul>
</div>

          
  <div class="clubawards">
  <ul fontSize="xl" py="50">            
    <h1>CLUB SOFTBLL ATHLETIC ACCOMPLISHMENTS</h1>
    <li>2021 PGF Nationals - Platinum Pool</li>
    <li>2021 PGF Nationals - Platinum Pool</li>
    <li>2021 TC Nationals</li>
    <li>2021 IDT Louisville Slugger Softball Tournament</li>
    <li>2021 OnDeck - Colorado Jamboree Monday</li>
    <li>2021 Triple Crown International Challenge - 🇺🇸TEAM USA VS THE WORLD</li>
    <li>2021 Triple Crown International Challenge - 🇺🇸Team USA Red</li>
    <li>2020 DNP COVID-19 The Triple Crown Softball International Challenge 🇺🇸 Team USA Red 🇺🇸</li>
    <li>2019 OnDeck West Coast Elite Camp - Rosetta Canyon Sports Park - Lake Elsinore, CA</li>
    <li>2019 New England's Finest Fall Showcase - Massachusetts</li>
    <li>2019 PGF National Championship 18U - Platinum Division - Irvine, CA</li>
    <li>2019 Colorado Sparkler - Westminster, CO</li>
    <li>2019 OnDeck - Rosetta Canyon Jamboree</li>
    <li>2018 OnDeck - West Coast Elite Camp</li>
    <li>2018 PGF National Championship 18U - Platinum Division - Irvine, CA</li>
    <li>2018 Power Pool All-Star Game at TCS Colorado Sparkler</li>
    <li>2018 TCS Colorado Sparkler 18U - Power Pool - Aurora, CO</li>
    <li>2018 Bomber Organizational Tournament - Austin, TX</li>
    <li>2018 Surf City - California</li>
    <li>2018 OnDeck Jamboree - Hemet, CA</li>
</ul>
</div>


<div class="schoolawards">
<ul fontSize="xl" py="50">
<h1>ACADEMIC ACCOMPLISHMENTS ACCOMPLISHMENTS</h1>
  <li>High School Cumulative GPA - 4.75</li>
  <li>2020-2021 All Honors Cambridge Courses</li>
  <li>2019-2020 All Honors Cambridge Courses</li>
  <li>2018-2019 All Honors Cambridge Courses</li>
  <li>2017-2018 8th Grade, NJHS Member, CR News Anchor, Softball Team (District Champions), Football Team</li>
  <li>2016–2017 7th Grade, NJHS Member, CR News Anchor, CR Reading Buddy Leader, Softball Team (District Champions)</li>
  <li>2015–2016 6th Grade, NEHS Member, CR News Anchor, Choir, Rachel's Challenge Leader</li>
  <li>2014–2015 5th Grade, NEHS Member, CR News Anchor, Choir</li>
</ul>
</div>
</div>
          {buttons}
        </div>
        </div>
    </section>
  )
}
