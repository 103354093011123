import * as React from "react"
import "/src/css/list.css"
import Block from "/src/components/header-002"
import BlockACC from "/src/components/call-to-action-005"


export default function Example() {
  return (
    <>
    <Block
      minH="500|0"
      branding={{
        name: "A1",
      }}
      links={[
        {
          title: "SOFTBALL HIGHLIGHTS",
          href: "/softball-highlights",
        },
        {
          title: "SCHEDULE",
          href: "/softball-schedule",
        },
        {
          title: "ACCOMPLISHMENTS",
          href: "/accomplishments-awards",
        },
        {
          title: "2ND SPORT WRESTLING",
          href: "/wrestling-highlights",
        },
      ]}
      buttonLink={{
        title: "NCAA ID# 2009918132",
        href: "#",
      }}
    />
    <BlockACC
      subheading="AWARDS"
      heading="Athletic - Academic Accomplishments"
      text="Freshmen Year LA Tech 2022 - Willow Canyon High School Student Athlete, Varsity Softball and Wrestling Team. Club Softball Team AZ Hotshots 18u Gold - Georges. Bats: Left - Throws Left - Height 5' 1'' - Weight 118lbs - Graduates 2022 - GPA 4.65"      
      />
    
    </>
  )
}
